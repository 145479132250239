<template>
  <section id="dashboard-ecommerce" v-if="treeData">
    <b-row>
      <b-col>
        <h1>View Members Structure</h1>
      </b-col>
      <b-col class="text-right">
        <b-button
          type="button"
          variant="primary"
          @click="$router.push('/pages/admin/sponsee')"
        >
          Back
        </b-button>
      </b-col>
    </b-row>

    <b-card>
      <b-row>
        <b-col cols="6">
          <label class="h5">Member Information</label>
        </b-col>

        <b-col cols="6" class="text-right">
          <feather-icon
            @click="hideMI = !hideMI"
            class="pin isDisplaying"
            :class="{ arrow_down: hideMI, arrow_up: !hideMI }"
            icon="ChevronUpIcon"
            size="36"
          />
        </b-col>
      </b-row>
      <span v-if="!hideMI">
        <b-row>
          <b-col>
            <b-form-group label="Parent Acct ID" label-for="v-parent-acct-id">
              <b-form-input
                disabled
                v-model="selectedNodeData.name"
                id="v-parent-acct-id"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="User Acct Name" label-for="v-user-acct-name">
              <b-form-input
                disabled
                v-model="selectedNodeData.account_name"
                id="v-user-acct-name"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Email" label-for="v-email">
              <b-form-input
                disabled
                v-model="selectedNodeData.email"
                id="v-email"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </span>

      <b-row>
        <b-col cols="12">
          <b-form-group label="" label-for="v-search">
            <b-input-group id="v-search" class="input-group-merge">
              <b-form-input v-model="keyword" placeholder="Search..." />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  icon="SearchIcon"
                  @click="search()"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="treeData">
        <b-col cols="12" md="7">
          <b-card class="">
            <tree
              ref="treeRef"
              :nodeTextDisplay="`all`"
              :identifier="getId"
              :nodeTextMargin="10"
              :leafTextMargin="10"
              :zoomable="true"
              :data="treeData"
              :node-text="`name`"
              :margin-x="0"
              :margin-y="0"
              :radius="5"
              :type="`tree`"
              :layout-type="`horizontal`"
              :linkLayout="`bezier`"
              :duration="750"
              :minZoom="0.3"
              :maxZoom="9"
              contextMenuPlacement="bottom-start"
              class="tree"
              @clickedText="onClickItem"
              @clickedNode="onClickItem"
            >
              <template #node="{ data, radius }">
                <template>
                  <circle
                    :r="radius"
                    stroke-width="2"
                    :stroke="
                      treeNode(data)
                        ? selectedNode(data)
                          ? 'green'
                          : 'blue'
                        : 'red'
                    "
                  >
                    <title v-if="data.username">
                      {{ tooltip(data) }}
                    </title>
                  </circle>
                </template>
              </template>
            </tree>
          </b-card>
        </b-col>

        <b-col cols="12" md="5">
          <MemberInformation
            @onSubmitResponse="(v) => loadFunctions(v)"
            v-if="treeData"
            :member-data="selectedNodeData"
            :users-data="getMembersBySponsorId(userMemberships)"
          />
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import userApi from '@api/users';
import userMembershipApi from '@api/user_memberships';
import { tree } from 'vued3tree';
import MemberInformation from './components/MemberInformation';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { queryParameters } from '@/schema';

export default {
  name: 'UserMembershipView',
  components: {
    tree,

    MemberInformation,
  },
  data: () => ({
    treeData: {},
    userMemberships: [],
    users: [],
    selectedNodeData: {},
    hideMI: false,
    keyword: '',

    formMemberInformation: {
      username: '',
      email: '',
      account_name: '',
    },
  }),
  created() {
    this.loadFunctions();
  },
  mounted() {
    //
  },
  watch: {
    keyword(search) {
      if (search) {
        const foundUser = this.userMemberships.find((x) => {
          return (
            x.username === search ||
            x.email === search ||
            x.account_id === search ||
            x.account_name === search
          );
        });

        if (foundUser && foundUser.tree_type !== 'Me') {
          foundUser.name = foundUser.username;
          foundUser.children = this.getTreeChildren(foundUser);

          this.onClickItem({ data: foundUser });
          return;
        }

        const td = this.treeData;
        const findingRoot =
          td.name === search ||
          td.email === search ||
          td.account_id === search ||
          td.account_name === search;

        const foundRoot = findingRoot ? td : '';

        if (foundRoot) {
          this.onClickItem({ data: foundRoot });
          return;
        }

        this.onClickItem({ data: td });
      }
    },
  },
  methods: {
    loadFunctions(item = null) {
      this.loadUsers();
      this.loadUserMembers(item);
    },
    loadUsers() {
      const userData = JSON.parse(window.localStorage.getItem('userData'));

      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.filterExt = `user_type='m'`;

      if (userData && userData.role !== 'admin') {
        params.filterExt += `,tree_id=${userData.id}|sponsor_id=${userData.id}`;
      }

      userApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            this.users = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadUser(item) {
      const isUUID = this.$route.query.id.match(
        /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/
      );

      if (isUUID) {
        const params = {
          sort: queryParameters.sort,
          page: queryParameters.page,
          status: queryParameters.status,
        };

        params.filterExt = `uuid='${this.$route.query.id}'`;

        userApi
          .list(params)
          .then(({ data }) => {
            if (data) {
              const root = {
                root_id: +data[0].id,
                name: data[0].username,
                email: data[0].email,
                account_id: '',
                account_name: '',
                children: this.getDataIteration(data[0]),
              };

              this.treeData = root;

              if (item && item.selected) {
                this.selectedNodeData = item.selected;
              } else {
                this.selectedNodeData = root;
              }
            }
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
    loadUserMembers(item) {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      userMembershipApi
        .list(params)
        .then(({ data }) => {
          this.userMemberships = data;
          this.loadUser(item);
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    getDataIteration(data) {
      const items = [];

      data.members.data.forEach((e) => {
        const user = this.users.find((x) => {
          return x.username === e.username;
        });

        const isSameUser = () => {
          return user && user.username !== data.username
            ? this.getTreeChildren(user)
            : [];
        };

        if (e.tree_type !== 'Me') {
          const item = {
            id: +e.id,
            data_id: user ? +user.id : 0,
            name: e.username ? e.username : e.tree_type,
            email: e.email,
            username: e.username,
            tree_type: e.tree_type,
            account_id: e.account_id,
            account_name: e.account_name,
            children: e.username ? isSameUser() : [],
          };

          items.push(item);
        }
      });

      return items || [];
    },
    getTreeChildren(child) {
      const children = [];

      const user = this.users.find((x) => {
        return x.username === child.username;
      });

      const isSameUser = (member) => {
        return member.username && member.username !== child.username
          ? this.getTreeChildren(member)
          : [];
      };

      if (user) {
        const members = this.userMemberships.filter((x) => {
          return +x.parent_id === +user.id;
        });

        members.forEach((member) => {
          if (member.tree_type !== 'Me') {
            const child = {
              id: +member.id,
              data_id: user ? +user.id : 0,
              name: member.username ? member.username : member.tree_type,
              email: member.email,
              username: member.username,
              tree_type: member.tree_type,
              account_id: member.account_id,
              account_name: member.account_name,
              children: isSameUser(member),
            };

            children.push(child);
          }
        });
      }

      return children;
    },
    onClickItem(res) {
      const data = res.data || {};
      const selectedUser = this.users.find((x) => {
        return x.username == data.name;
      });

      if (data && data.username !== '') {
        data.data_id = +selectedUser.id;
        this.selectedNodeData = data;
        return;
      }
    },
    getId(node) {
      return node.id;
    },
    getMembersBySponsorId(members) {
      const membersBySponsorId = members.filter((x) => {
        const userData = JSON.parse(window.localStorage.getItem('userData'));

        if (userData) {
          if (userData.role === 'admin') {
            return x.username !== '' && x.tree_type !== 'Me';
          }

          return (
            x.sponsor_id === +userData.id &&
            x.username !== '' &&
            x.tree_type !== 'Me'
          );
        }
      });

      if (membersBySponsorId) {
        return membersBySponsorId;
      }
    },
    treeNode(data) {
      return data.children && data.children.length > 0 && data.username !== '';
    },
    selectedNode(data) {
      return (
        data.username !== '' && data.username === this.selectedNodeData.username
      );
    },
    search() {
      //
    },
    toastMessage() {
      const data = {
        component: ToastificationContent,
        position: 'top-center',
        props: {
          title: `Error`,
          icon: 'AlertCircleIcon',
          variant: 'danger',
          text: `User does not exists on tree data.`,
        },
      };

      this.$toast(data);
    },
    tooltip(data) {
      const template = `
      Username : ${data.username || ''}
Account ID : ${data.account_id || ''}
      `;

      return template;
    },
  },
};
</script>

<style>
.tree {
  height: 100vh;
}
</style>
