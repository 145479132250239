<template>
  <span>
    <b-card>
      <b-row @click="hideL = !hideL">
        <b-col cols="6">
          <label class="h5">Left</label>
        </b-col>

        <b-col cols="6" class="text-right">
          <feather-icon
            class="pin isDisplaying"
            :class="{ arrow_down: hideL, arrow_up: !hideL }"
            icon="ChevronUpIcon"
            size="36"
          />
        </b-col>
      </b-row>
      <span v-if="!hideL">
        <b-row>
          <b-col>
            <b-form-group label="Username" label-for="v-l-user-acct-name">
              <v-select
                :disabled="disableFormL"
                v-model="formLeft.username"
                id="v-l-user-acct-name"
                label="username"
                :options="onGetAvailableUser('L')"
                :reduce="(users) => users.username"
                @option:selected="onSelectUser($event, 'L')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Email" label-for="v-l-email">
              <b-form-input disabled v-model="formLeft.email" id="v-l-email" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Acct ID" label-for="v-l-acct-id">
              <b-form-input
                :disabled="disableFormL"
                v-model="formLeft.account_id"
                id="v-l-acct-id"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Acct Name" label-for="v-l-acct-name">
              <b-form-input
                :disabled="disableFormL"
                v-model="formLeft.account_name"
                id="v-l-acct-name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-form-group>
              <b-button
                :disabled="disableFormL"
                v-b-modal.modal-confirmation
                type="button"
                variant="primary"
                @click="submitTreeType = 'L'"
              >
                Add
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </span>

      <b-row @click="hideR = !hideR">
        <b-col cols="6">
          <label class="h5">Right</label>
        </b-col>

        <b-col cols="6" class="text-right">
          <feather-icon
            class="pin isDisplaying"
            :class="{ arrow_down: hideR, arrow_up: !hideR }"
            icon="ChevronUpIcon"
            size="36"
          />
        </b-col>
      </b-row>
      <span v-if="!hideR">
        <b-row>
          <b-col>
            <b-form-group label="Username" label-for="v-r-user-acct-name">
              <v-select
                :disabled="disableFormR"
                v-model="formRight.username"
                id="v-r-user-acct-name"
                label="username"
                :options="onGetAvailableUser('R')"
                :reduce="(users) => users.username"
                @option:selected="onSelectUser($event, 'R')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Email" label-for="v-r-email">
              <b-form-input disabled v-model="formRight.email" id="v-r-email" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Acct ID" label-for="v-r-acct-id">
              <b-form-input
                :disabled="disableFormR"
                v-model="formRight.account_id"
                id="v-r-acct-id"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Acct Name" label-for="v-r-acct-name">
              <b-form-input
                :disabled="disableFormR"
                v-model="formRight.account_name"
                id="v-r-acct-name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-form-group>
              <b-button
                :disabled="disableFormR"
                v-b-modal.modal-confirmation
                type="button"
                variant="primary"
                @click="submitTreeType = 'R'"
              >
                Add
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </span>

      <b-row @click="hideP1 = !hideP1">
        <b-col cols="6">
          <label class="h5">P1</label>
        </b-col>

        <b-col cols="6" class="text-right">
          <feather-icon
            class="pin isDisplaying"
            :class="{ arrow_down: hideP1, arrow_up: !hideP1 }"
            icon="ChevronUpIcon"
            size="36"
          />
        </b-col>
      </b-row>
      <span v-if="!hideP1">
        <b-row>
          <b-col>
            <b-form-group label="Username" label-for="v-p1-user-acct-name">
              <v-select
                :disabled="disableFormP1"
                v-model="formP1.username"
                id="v-p1-user-acct-name"
                label="username"
                :options="onGetAvailableUser('P1')"
                :reduce="(users) => users.username"
                @option:selected="onSelectUser($event, 'P1')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Email" label-for="v-p1-email">
              <b-form-input disabled v-model="formP1.email" id="v-p1-email" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Acct ID" label-for="v-p1-acct-id">
              <b-form-input
                :disabled="disableFormP1"
                v-model="formP1.account_id"
                id="v-p1-acct-id"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Acct Name" label-for="v-p1-acct-name">
              <b-form-input
                :disabled="disableFormP1"
                v-model="formP1.account_name"
                id="v-p1-acct-name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-form-group>
              <b-button
                :disabled="disableFormP1"
                v-b-modal.modal-confirmation
                type="button"
                variant="primary"
                @click="submitTreeType = 'P1'"
              >
                Add
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </span>

      <b-row @click="hideP2 = !hideP2">
        <b-col cols="6">
          <label class="h5">P2</label>
        </b-col>

        <b-col cols="6" class="text-right">
          <feather-icon
            class="pin isDisplaying"
            :class="{ arrow_down: hideP2, arrow_up: !hideP2 }"
            icon="ChevronUpIcon"
            size="36"
          />
        </b-col>
      </b-row>
      <span v-if="!hideP2">
        <b-row>
          <b-col>
            <b-form-group label="Username" label-for="v-p2-user-acct-name">
              <v-select
                :disabled="disableFormP2"
                v-model="formP2.username"
                id="v-p2-user-acct-name"
                label="username"
                :options="onGetAvailableUser('P2')"
                :reduce="(users) => users.username"
                @option:selected="onSelectUser($event, 'P2')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Email" label-for="v-p2-email">
              <b-form-input disabled v-model="formP2.email" id="v-p2-email" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Acct ID" label-for="v-p2-acct-id">
              <b-form-input
                :disabled="disableFormP2"
                v-model="formP2.account_id"
                id="v-p2-acct-id"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Acct Name" label-for="v-p2-acct-name">
              <b-form-input
                :disabled="disableFormP2"
                v-model="formP2.account_name"
                id="v-p2-acct-name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-form-group>
              <b-button
                :disabled="disableFormP2"
                v-b-modal.modal-confirmation
                type="button"
                variant="primary"
                @click="submitTreeType = 'P2'"
              >
                Add
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </span>

      <b-row @click="hideP3 = !hideP3">
        <b-col cols="6">
          <label class="h5">P3</label>
        </b-col>

        <b-col cols="6" class="text-right">
          <feather-icon
            class="pin isDisplaying"
            :class="{ arrow_down: hideP3, arrow_up: !hideP3 }"
            icon="ChevronUpIcon"
            size="36"
          />
        </b-col>
      </b-row>
      <span v-if="!hideP3">
        <b-row>
          <b-col>
            <b-form-group label="Username" label-for="v-p3-user-acct-name">
              <v-select
                :disabled="disableFormP3"
                v-model="formP3.username"
                id="v-p3-user-acct-name"
                label="username"
                :options="onGetAvailableUser('P3')"
                :reduce="(users) => users.username"
                @option:selected="onSelectUser($event, 'P3')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Email" label-for="v-p3-email">
              <b-form-input disabled v-model="formP3.email" id="v-p3-email" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Acct ID" label-for="v-p3-acct-id">
              <b-form-input
                :disabled="disableFormP3"
                v-model="formP3.account_id"
                id="v-p3-acct-id"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Acct Name" label-for="v-p3-acct-name">
              <b-form-input
                :disabled="disableFormP3"
                v-model="formP3.account_name"
                id="v-p3-acct-name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-form-group>
              <b-button
                :disabled="disableFormP3"
                v-b-modal.modal-confirmation
                type="button"
                variant="primary"
                @click="submitTreeType = 'P3'"
              >
                Add
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </span>
    </b-card>

    <Modal modalId="modal-confirmation" @onChange="(v) => onSubmit()" />
  </span>
</template>

<script>
import userApi from '@api/users';
import userMembershipApi from '@api/user_memberships';
import { queryParameters } from '@/schema';

const formCommonFields = {
  username: '',
  email: '',
  parent_id: 0,
  account_id: '',
  account_name: '',
  membership_status: 'new',
  quantity_active_indirect: 0,
  quantity_active_direct: 0,
};

export default {
  name: 'MembershipInformationComponent',
  props: {
    memberData: {
      type: Object,
      default: () => {},
    },
    usersData: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    submitTreeType: '',
    hideL: true,
    hideR: true,
    hideP1: true,
    hideP2: true,
    hideP3: true,

    disableFormL: false,
    disableFormR: false,
    disableFormP1: false,
    disableFormP2: false,
    disableFormP3: false,

    users: [],
    membersBySponsorId: [],
    selectedMember: {},

    formLeft: {
      ...formCommonFields,
      tree_type: 'L',
    },

    formRight: {
      ...formCommonFields,
      tree_type: 'R',
    },

    formP1: {
      ...formCommonFields,
      tree_type: 'P1',
    },

    formP2: {
      ...formCommonFields,
      tree_type: 'P2',
    },

    formP3: {
      ...formCommonFields,
      tree_type: 'P3',
    },
  }),
  watch: {
    usersData(data) {
      this.membersBySponsorId = data;
    },
    memberData(data) {
      this.selectedMember = data;
      if (data) {
        data.children.forEach((child) => {
          switch (child.tree_type) {
            case 'L':
              if (child.name != 'L') {
                this.hideL = false;
                this.disableFormL = true;
              } else {
                this.hideL = true;
                this.disableFormL = false;
              }

              this.formLeft.username = child.name;
              this.formLeft.account_id = child.account_id;
              this.formLeft.account_name = child.account_name;
              this.onSelectUser(child, 'L');
              break;

            case 'R':
              if (child.name != 'R') {
                this.hideR = false;
                this.disableFormR = true;
              } else {
                this.hideR = true;
                this.disableFormR = false;
              }

              this.formRight.username = child.name;
              this.formRight.account_id = child.account_id;
              this.formRight.account_name = child.account_name;
              this.onSelectUser(child, 'R');
              break;

            case 'P1':
              if (child.name != 'P1') {
                this.hideP1 = false;
                this.disableFormP1 = true;
              } else {
                this.hideP1 = true;
                this.disableFormP1 = false;
              }

              this.formP1.username = child.name;
              this.formP1.account_id = child.account_id;
              this.formP1.account_name = child.account_name;
              this.onSelectUser(child, 'P1');
              break;

            case 'P2':
              if (child.name != 'P2') {
                this.hideP2 = false;
                this.disableFormP2 = true;
              } else {
                this.hideP2 = true;
                this.disableFormP2 = false;
              }

              this.formP2.username = child.name;
              this.formP2.account_id = child.account_id;
              this.formP2.account_name = child.account_name;
              this.onSelectUser(child, 'P2');
              break;

            case 'P3':
              if (child.name != 'P3') {
                this.hideP3 = false;
                this.disableFormP3 = true;
              } else {
                this.hideP3 = true;
                this.disableFormP3 = false;
              }

              this.formP3.username = child.name;
              this.formP3.account_id = child.account_id;
              this.formP3.account_name = child.account_name;
              this.onSelectUser(child, 'P3');
              break;

            default:
              break;
          }
        });
      }
    },
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      const userData = JSON.parse(window.localStorage.getItem('userData'));

      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.filterExt = `user_type='m',active=true`;

      if (userData && userData.role !== 'admin') {
        params.filterExt += `,tree_id=${userData.id}|sponsor_id=${userData.id}`;
      }

      userApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            this.users = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onGetAvailableUser(type) {
      const selectedUsers = [];
      const availableUsers = [];

      this.membersBySponsorId.forEach((member) => {
        selectedUsers.push(member.username);
      });

      this.users.forEach((user) => {
        // if (type === "L" || type === "R") {
        //   if (!selectedUsers.includes(user.username)) {
        //     availableUsers.push(user);
        //   }

        //   return;
        // }

        availableUsers.push(user);
      });

      return availableUsers;
    },
    onSelectUser(event, treetype) {
      switch (treetype) {
        case 'L':
          this.formLeft.email = event.email;
          break;

        case 'R':
          this.formRight.email = event.email;
          break;

        case 'P1':
          this.formP1.email = event.email;
          break;

        case 'P2':
          this.formP2.email = event.email;
          break;

        case 'P3':
          this.formP3.email = event.email;
          break;

        default:
          break;
      }
    },
    onSubmit() {
      let formData = {};
      switch (this.submitTreeType) {
        case 'L':
          formData = this.formLeft;
          break;

        case 'R':
          formData = this.formRight;
          break;

        case 'P1':
          formData = this.formP1;
          break;

        case 'P2':
          formData = this.formP2;
          break;

        case 'P3':
          formData = this.formP3;
          break;

        default:
          break;
      }

      if (formData) {
        const userData = JSON.parse(window.localStorage.getItem('userData'));

        if (this.selectedMember.data_id) {
          formData.parent_id = this.selectedMember.data_id;
          formData.selected = this.selectedMember;
        } else {
          formData.parent_id = +userData.id;
        }

        userMembershipApi
          .updateTree(formData)
          .then(({ data }) => {
            if (data) {
              switch (formData.tree_type) {
                case 'L':
                  this.disableFormL = true;
                  break;

                case 'R':
                  this.disableFormR = true;
                  break;

                case 'P1':
                  this.disableFormP1 = true;
                  break;

                case 'P2':
                  this.disableFormP2 = true;
                  break;

                case 'P3':
                  this.disableFormP3 = true;
                  break;

                default:
                  break;
              }

              this.$emit('onSubmitResponse', formData);
            }
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }

      this.submitTreeType = '';
    },
  },
};
</script>

<style>
.pin:hover {
  cursor: pointer;
}

.isDisplaying.arrow_down {
  transform: rotate(180deg);
  transition: transform 0.25s linear;
}

.isDisplaying.arrow_up {
  transform: rotate(360deg);
  transition: transform 0.25s linear;
}
</style>
