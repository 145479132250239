import api from "@apiInstance"

export default {
  list(params) {
    return api.fetch('usermemberships', { params });
  },
  updateTree(body) {
    return api.request({
      url: '/usermemberships/updatetree',
      method: 'POST',
      body,
      type: 'usermemberships'
    });
  },
  updateTreeMultiple(body) {
    return api.request({
      url: '/usermemberships/updatetreemultiple',
      method: 'POST',
      body,
      type: 'usermemberships'
    });
  },
}